import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/solid';
import { CircleArrowOutUpRight, CreditCard } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

import { useGetCurrentUserQuery } from '../../graphql/getCurrentUser.generated';
import Logo from '../Icons/Logo';
import { useModal } from '../ModalProvider/ModalProvider';
import { Button } from '../ui/button';

function Navbar({ className }: { className?: string }) {
  const [{ data }] = useGetCurrentUserQuery();
  const isAuthenticated = !!data?.currentUser;
  const { pathname } = useRouter();
  const { openModal } = useModal();

  return (
    <div className={`flex justify-between py-6 px-12 ${className}`}>
      <div className="flex items-center font-serif">
        <Logo />
        <Link href={isAuthenticated ? `/dashboard` : `/`} className="font-bold ml-4 text-xl">
          stackblocks
        </Link>
      </div>
      {isAuthenticated ? (
        <>
          {pathname.startsWith('/dashboard') ? (
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="bg-gray-100 flex  rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                  {data.currentUser?.image ? (
                    <img
                      className="h-7 w-7 rounded-full ring ring-orange-500"
                      src={data.currentUser?.image}
                      alt="user avatar"
                    />
                  ) : (
                    <UserCircleIcon className="text-gray-800 h-8 w-8 rounded-full" />
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } flex gap-2 items-center px-4 py-2  text-gray-700`}
                        onClick={() => {
                          openModal('BILLING');
                        }}
                      >
                        <CreditCard size={16} />
                        Billing
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/logout"
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } flex gap-2 items-center px-4 py-2  text-gray-700`}
                      >
                        <CircleArrowOutUpRight size={14} />
                        Log out
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <Link href="/dashboard" className="">
              <Button variant="secondary">Go to App</Button>
            </Link>
          )}
        </>
      ) : (
        <div className="gap-x-8 hidden sm:flex items-center">
          <Link href="/blog" className=" hover:text-gray-700">
            Blog
          </Link>
          <Link href="/#features" className=" hover:text-gray-700">
            Features
          </Link>
          <Link href="/pricing" className=" hover:text-gray-700">
            Pricing
          </Link>
          <Link href="/login" className="">
            <Button variant="ghost">Log In</Button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;
