import { Popover } from '@headlessui/react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';

type Props = {
  disabled?: boolean;
  color?: string;
  onChange: (color: string) => void;
};

const ColourPicker = ({ color = '#ddd', onChange, disabled }: Props) => {
  return (
    <Popover className="relative flex items-center">
      <Popover.Button
        disabled={disabled}
        name="colour-button"
        style={
          color.length === 9 && color.endsWith('00')
            ? {
                backgroundImage: 'url("./transparent.jpg")',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }
            : { backgroundColor: color }
        }
        className={`w-7 h-7 inline-block border-2 rounded`}
      />

      <Popover.Panel className="absolute z-10 bottom-8 right-0">
        <div className="p-4 shadow-md rounded border bg-white flex flex-col items-center gap-4 relative">
          <HexAlphaColorPicker color={color} onChange={onChange} />
          <HexColorInput
            alpha
            color={color}
            onChange={onChange}
            className="border rounded pl-5 py-1 outline-orange-500 focus:outline-none focus:border-orange-500"
          />
          <span className="absolute bottom-6 left-5 text-sm text-gray-500">#</span>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default ColourPicker;
