import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { ReactNode } from 'react';
import { Badge } from './ui/badge';

type Props = {
  defaultOpen?: boolean;
  title: string;
  flair?: string;
  titleClass?: string;
  iconLeft?: ReactNode;
  children: ReactNode;
};

const CollapsibleSection = ({
  defaultOpen,
  title,
  titleClass = '',
  flair,
  iconLeft,
  children,
}: Props) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 font-medium text-left text-orange-900 bg-orange-100 rounded-lg hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
            <div className="flex items-center gap-x-2">
              {iconLeft}
              <span className={titleClass}>{title}</span>
              {flair && (
                <Badge
                  variant="secondary"
                  className="text-orange-700 bg-orange-50 hover:bg-orange-50"
                >
                  {flair}
                </Badge>
              )}
            </div>
            <ChevronUpIcon
              className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-orange-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pb-2">{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CollapsibleSection;
